import { Form, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

export const CitiesFormDropdown = () => {
  const [dropdownData, setDropDownData] = useState([]);

  const getCities = () => {
    axios
      .get(".netlify/functions/getCities", {
        baseURL: "/",
      })
      .then((response) => {
        setDropDownData(response.data.data);
      });
  };

  useEffect(() => {
    getCities();
  }, []);
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const listOptions = dropdownData.map((data) => {
    return {
      value: data.ref,
      label: data.text,
    };
  });
  console.log(dropdownData);
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Form.Item
      label="City"
      name="city"
      rules={[
        {
          required: true,
          message: "Select a City",
        },
      ]}
    >
      <Select
        showSearch
        placeholder="Select a City"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={listOptions}
      />
    </Form.Item>
  );
};
