import { Card, Col, Divider, Form, Row } from "antd";
import { useEffect } from "react";
import {
  InputFormItemNumberUnitsUpdate,
  InputFormItemTextAreaUnitsEdit,
  InputFormItemUnitsUpdate,
} from "./formComponentsUnits";

export const ListUnitsFormUpdate = ({ unitData }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...unitData,
    });
  }, [form, unitData]);

  console.log(unitData);

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={12}>
            <InputFormItemUnitsUpdate
              unitId={unitData.id}
              label={"Source URL"}
              name={"sourceUrl"}
              required={true}
              message={"Enter Source Url"}
            />
          </Col>
          <Col xs={24} sm={24} md={4}>
            <InputFormItemNumberUnitsUpdate
              unitId={unitData.id}
              label={"Price"}
              name={"price"}
              required={true}
              message={"Enter Unit Price"}
            />
          </Col>
          <Col xs={24} sm={24} md={3}>
            <InputFormItemNumberUnitsUpdate
              unitId={unitData.id}
              label={"Bedrooms"}
              name={"bedrooms"}
              required={true}
              message={"Enter Number of bedrooms"}
            />
          </Col>
          <Col xs={24} sm={24} md={4}>
            <InputFormItemNumberUnitsUpdate
              unitId={unitData.id}
              label={"Bathrooms"}
              name={"bathrooms"}
              required={true}
              message={"Enter number of bathrooms"}
            />
          </Col>
          <Col xs={24} sm={24} md={4}>
            <InputFormItemNumberUnitsUpdate
              unitId={unitData.id}
              label={"SquareFeet"}
              name={"squareFeet"}
              required={true}
              message={"Enter square feet"}
            />
          </Col>
          <Col xs={24} sm={24} md={4}>
            <InputFormItemNumberUnitsUpdate
              unitId={unitData.id}
              label={"Floor"}
              name={"floor"}
              required={true}
              message={"Enter the floor number"}
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <InputFormItemTextAreaUnitsEdit
              unitId={unitData.id}
              label={"description"}
              name={"description"}
              required={true}
              message={"Enter Development Name"}
            />
          </Col>
          <Col xs={24} sm={24} md={7}>
            <InputFormItemUnitsUpdate
              unitId={unitData.id}
              label={"YouTube"}
              name={"youTubeUrl"}
              required={false}
              message={"Enter Development Name"}
            />
          </Col>
          <Col xs={24} sm={24} md={7}>
            <InputFormItemUnitsUpdate
              unitId={unitData.id}
              label={"Vimeo"}
              name={"vimeoUrl"}
              required={false}
              message={"Enter Development Name"}
            />
          </Col>
          <Col xs={24} sm={24} md={7}>
            <InputFormItemUnitsUpdate
              unitId={unitData.id}
              label={"Matterport"}
              name={"matterportUrl"}
              required={false}
              message={"Enter Development Name"}
            />
          </Col>
        </Row>
      </Form>
      <Divider />
    </Card>
  );
};
