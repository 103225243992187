import React from "react";
import { Col, Row, Switch, Table } from "antd";
import { ListUnitsFormUpdate } from "./listUnitsFormUpdate";

import instance from "../../utils/axiosConfig";
import { UpdateIntermediaryIndividuals } from "./updateIntermediaryIndividuals";

import { FormComponentImagesSaleUpdate } from "./formComponentImagesSaleUpdate";

export const ListSaleUnitsTable = ({ listUnitsData, setUnitData }) => {
  const updateUnit = async (checked, record) => {
    await instance
      .post(".netlify/functions/updateUnit", {
        baseURL: "/",
        params: {
          data: { published: checked },
          unitId: record.id,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          return Promise.resolve();
        } else {
          return Promise.reject(new Error("error"));
        }
      });
  };

  const columns = [
    // {
    //   title: "Developement",
    //   dataIndex: "developmentData",
    //   key: "developmentData",
    //   render: (record) => record.data.developmentName,
    // },
    {
      title: "bedroom",
      dataIndex: "bedrooms",
      key: "bedrooms",
    },
    {
      title: "bathrooms",
      dataIndex: "bathrooms",
      key: "bathrooms",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "SquareFeet",
      dataIndex: "squareFeet",
      key: "squareFeet",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },

    {
      title: "Status",
      key: "status",
      render: (record) => (
        <>
          <Switch
            onChange={(checked) => {
              updateUnit(checked, record);
            }}
            checkedChildren={"Active"}
            unCheckedChildren={"offline"}
            defaultChecked={record.published}
          />
        </>
      ),
    },
  ];

  return (
    <Row gutter={[18, 18]}>
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={listUnitsData}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <UpdateIntermediaryIndividuals
                  intermediaryData={record.contactInfo}
                />

                <ListUnitsFormUpdate unitData={record} />
                <FormComponentImagesSaleUpdate unitData={record} />
              </>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
        />
      </Col>
    </Row>
  );
};
