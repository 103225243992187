import { Form, Input, Radio } from "antd";
import instance from "../../utils/axiosConfig";

const { TextArea } = Input;

export const InputFormItemIntermediary = ({
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <Input />
    </Form.Item>
  );
};
export const InputFormItemTextAreaIntermediary = ({
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <TextArea rows={4} />
    </Form.Item>
  );
};

export const InputFormItemIntermediaryUpdate = ({
  organisationRef,
  label,
  name,
  required,
  message,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        style={{ margin: "0px" }}
        name={name}
        rules={[
          { required: required, message: message },
          {
            validator: async (_, value) => {
              await instance
                .post(".netlify/functions/updateIntermediaryOrganisation", {
                  baseURL: "/",
                  params: {
                    data: { [name]: value },
                    organisationRef,
                  },
                })

                .then(function (response) {
                  if (response.status === 200) {
                    return Promise.resolve("success");
                  } else {
                    return Promise.reject(new Error("error"));
                  }
                });
            },
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
    </>
  );
};
export const InputFormItemIndividualUpdate = ({
  individualRef,
  label,
  name,
  required,
  message,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        style={{ margin: "0px" }}
        name={name}
        rules={[
          { required: required, message: message },
          {
            validator: async (_, value) => {
              await instance
                .post(".netlify/functions/updateIntermediaryIndividual", {
                  baseURL: "/",
                  params: {
                    data: { [name]: value },
                    individualRef,
                  },
                })

                .then(function (response) {
                  if (response.status === 200) {
                    return Promise.resolve("success");
                  } else {
                    return Promise.reject(new Error("error"));
                  }
                });
            },
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
    </>
  );
};

export const InputFormRadioUpdate = ({
  organisationRef,
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[
        { required: required, message: message },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateIntermediaryOrganisation", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  organisationRef,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
    >
      <Radio.Group defaultValue={false} buttonStyle="solid">
        <Radio.Button value={true}>{"Yes"}</Radio.Button>
        <Radio.Button value={false}>{"No"}</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

export const InputFormRadioUpdateIndividual = ({
  individualRef,
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[
        { required: required, message: message },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateIntermediaryIndividual", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  individualRef,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
    >
      <Radio.Group defaultValue={false} buttonStyle="solid">
        <Radio.Button value={true}>{"Yes"}</Radio.Button>
        <Radio.Button value={false}>{"No"}</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};
