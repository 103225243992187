import { Form, Radio } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

export const FormInputRadioFurnishingStatus = () => {
  const [radioData, setRadioData] = useState([]);
  const getFurnishingStatus = () => {
    axios
      .get(".netlify/functions/getFurnishingStatus", {
        baseURL: "/",
      })
      .then((response) => {
        setRadioData(response.data.data);
      });
  };

  useEffect(() => {
    getFurnishingStatus();
  }, []);

  const onChange = ({ target: { value } }) => {
    console.log("radio1 checked", value);
  };

  const listRadioData = radioData.map((data) => {
    return {
      label: data.title,
      value: data.ref,
    };
  });

  console.log(listRadioData);

  return (
    <Form.Item
      label="Furnishing Status"
      name="furnishingStatus"
      rules={[
        {
          required: true,
          message: "Select a the furnishing status",
        },
      ]}
    >
      <Radio.Group
        options={listRadioData}
        onChange={onChange}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>
  );
};
