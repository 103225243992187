import { Skeleton, Row, Col } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { IndividualsTable } from "./individualsTable";

export const ListIndividuals = () => {
  const [individualsData, setIndividualsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getIntermediaryIndividuals = () => {
    setLoading(true);
    axios
      .get(".netlify/functions/getIntermediaryIndividuals", {
        baseURL: "/",
      })
      .then((response) => {
        setIndividualsData(response.data.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getIntermediaryIndividuals();
  }, []);
  console.log(individualsData);

  return (
    <Skeleton loading={loading}>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <IndividualsTable individualsData={individualsData} />
        </Col>
      </Row>
    </Skeleton>
  );
};
