import { Card, Divider } from "antd";
import { UpdateIntermediaryModalForm } from "./updateIntermediaryModalForm";

export const UpdateIntermediaryIndividuals = ({ intermediaryData }) => {
  const listIntermediaries = intermediaryData.map((data) => {
    return <UpdateIntermediaryModalForm intermediaryData={data} />;
  });

  return (
    <Card>
      Intermediaries:
      <div style={{ display: "flex" }}>{listIntermediaries}</div>
      <Divider />
    </Card>
  );
};
