import { Button, Col, Row, Table, Input, Space } from "antd";
import { useRef, useState } from "react";
// import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { DevelopmentFormUpdate } from "./developmentFormUpdate";

export const DevelopmentDbTable = ({ developmentDb }) => {
  const [developmentData, setDevelopmentData] = useState([]);

  // table search feature
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  console.log(searchText);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          {
            // padding: 8,
          }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => searchedColumn === dataIndex && text,
  });

  const columns = [
    {
      title: "Development Name",
      dataIndex: "developmentName",
      key: "developmentName",
      ...getColumnSearchProps("developmentName"),
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            setDevelopmentData(record);
          }}
        >
          {text}
        </Button>
      ),
    },
  ];

  return (
    <Row gutter={[18, 18]}>
      <Col span={6}>
        <Table size="small" columns={columns} dataSource={developmentDb} />
      </Col>
      <Col span={18}>
        <DevelopmentFormUpdate developmentData={developmentData} />
      </Col>
    </Row>
  );
};
