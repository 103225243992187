import { Card, Col, Divider, Form, Row } from "antd";
import {
  InputFormItemUpdate,
  InputFormItemTextAreaUpdate,
  InputFormNumbersUpdate,
  InputFormNumbersGeneralUpdate,
} from "./formComponents";
import { useEffect } from "react";
import { FormComponentImages } from "./formComponentImages";
import { CitiesFormDropdownUpdate } from "./citiesFormDropdownUpdate";

export const DevelopmentFormUpdate = ({ developmentData }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...developmentData,
    });
  }, [form, developmentData]);

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={8}>
            <InputFormItemUpdate
              developmentId={developmentData.id}
              label={"Development Name"}
              name={"developmentName"}
              required={true}
              message={"Enter Development Name"}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <InputFormItemUpdate
              developmentId={developmentData.id}
              label={"Developer"}
              name={"developer"}
              required={true}
              message={"Enter Developer Name"}
            />
          </Col>

          <Col xs={24} sm={24} md={6}>
            <InputFormItemUpdate
              developmentId={developmentData.id}
              label={"Development Type"}
              name={"developmentType"}
              required={true}
              message={"Enter type of development"}
            />
          </Col>
          <Row gutter={[18, 18]}>
            <Col xs={24} sm={24} md={3}>
              <InputFormNumbersUpdate
                developmentId={developmentData.id}
                label={"#of Floors"}
                name={"floors"}
                required={true}
                message={"Enter the number of floors in the development"}
              />
            </Col>
            <Col xs={24} sm={24} md={4}>
              <InputFormNumbersGeneralUpdate
                developmentId={developmentData.id}
                label={"Completion"}
                name={"completionYear"}
                required={true}
                message={"Year the development was completed"}
              />
            </Col>
            <Col xs={24} sm={24} md={4}>
              <InputFormNumbersUpdate
                developmentId={developmentData.id}
                label={"Total Units"}
                name={"totalUnits"}
                required={true}
                message={"Year the development was completed"}
              />
            </Col>
            <Col xs={24} sm={24} md={4}>
              <InputFormNumbersUpdate
                developmentId={developmentData.id}
                label={"Service Charge"}
                name={"serviceCharge"}
                required={true}
                message={"Service Charge per month per sqft"}
              />
            </Col>
            <Col xs={24} sm={24} md={4}>
              <InputFormNumbersUpdate
                developmentId={developmentData.id}
                label={"Sinking Fund"}
                name={"sinikingFund"}
                required={true}
                message={"sinking fund"}
              />
            </Col>
            <Col xs={24} sm={24} md={4}>
              <InputFormItemUpdate
                developmentId={developmentData.id}
                label={"Tenure"}
                name={"tenure"}
                required={true}
                message={"Year the development was completed"}
              />
            </Col>
          </Row>
          <Col xs={24} sm={24} md={13}>
            <InputFormItemTextAreaUpdate
              developmentId={developmentData.id}
              label={"Description"}
              name={"description"}
              required={true}
              message={"Year the development was completed"}
            />
          </Col>
          <Col xs={24} sm={24} md={10}>
            <InputFormItemUpdate
              developmentId={developmentData.id}
              label={"Address"}
              name={"address"}
              required={true}
              message={"Year the development was completed"}
            />
          </Col>
          <Col xs={24} sm={24} md={10}>
            <CitiesFormDropdownUpdate developmentId={developmentData.id} />
          </Col>
          <Col xs={24} sm={24} md={14}>
            <InputFormItemUpdate
              developmentId={developmentData.id}
              label={"Google Location"}
              name={"googleLocation"}
              required={true}
              message={"Year the development was completed"}
            />
          </Col>
          <Col xs={24} sm={24} md={5}>
            <InputFormItemUpdate
              developmentId={developmentData.id}
              label={"Latitude"}
              name={"latitude"}
              required={true}
              message={"enter latitude"}
            />
          </Col>
          <Col xs={24} sm={24} md={5}>
            <InputFormItemUpdate
              developmentId={developmentData.id}
              label={"Longitude"}
              name={"longitude"}
              required={true}
              message={"enter longitude"}
            />
          </Col>
        </Row>
      </Form>
      <Divider />
      <FormComponentImages
        developmentId={developmentData.id}
        developmentData={developmentData}
      />
    </Card>
  );
};
