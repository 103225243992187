import { Col, Row, Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

export const Dashboard = () => {
  const [dashBoardStats, setdashBoardStats] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDashboardStats = () => {
    axios
      .get(".netlify/functions/getDashboardStats", {
        baseURL: "/",
      })
      .then((response) => {
        setdashBoardStats(response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDashboardStats();
  }, []);

  console.log(dashBoardStats);

  return (
    <div className="main">
      <Skeleton loading={loading} style={{ width: "1400px" }}>
        <Row gutter={[18, 18]}>
          <Col span={24}>
            <h1>Dashboard</h1>
          </Col>
          <Col span={24}>
            <ul>
              <li>Developments: {dashBoardStats.developmentCount} </li>
              <li>Agents: 3</li>
              <li>Units: 3</li>
              <li>For Sale: 3</li>
              <li>For Rent: 3</li>
            </ul>
          </Col>
        </Row>
      </Skeleton>
    </div>
  );
};
