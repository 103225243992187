import { Card, Col, Divider, Row, Tabs } from "antd";
import { AddUnitForm } from "./addUnitForm";

import { useState } from "react";
import { AddContactForm } from "./addContactForm";
import { SearchNumber } from "./SearchNumber";
import { AddUnitRentForm } from "./addUnitRentForm";

export const AddUnit = () => {
  const [contactInfo, setContactInfo] = useState([]);
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Rental Units",
      children: (
        <AddUnitRentForm
          contactInfo={contactInfo}
          setContactInfo={setContactInfo}
        />
      ),
    },
    {
      key: "2",
      label: "Sale Units",
      children: (
        <AddUnitForm
          contactInfo={contactInfo}
          setContactInfo={setContactInfo}
        />
      ),
    },
  ];

  return (
    <div className="main">
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <Card title={"number search"} style={{ margin: "20px" }}>
            <SearchNumber setContactInfo={setContactInfo} />
          </Card>
          <AddContactForm setContactInfo={setContactInfo} />
          <Divider />
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
        </Col>
      </Row>
    </div>
  );
};
