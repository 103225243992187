import { Button, Col, Row, Space } from "antd";

import { Link, Outlet } from "react-router-dom";

export const Enquiries = () => {
  const enquiryLinks = [
    {
      title: "List All Enquires",
      url: "list-enquiries",
    },
  ];

  const listEnquirylinks = enquiryLinks.map((data) => {
    return (
      <Col xs={24} sm={24} md={4} style={{ textAlign: "center" }}>
        <Link to={data.url}>
          <Button>{data.title}</Button>
        </Link>
      </Col>
    );
  });
  return (
    <Row gutter={[18, 18]}>
      <Col span={24} style={{ textAlign: "center" }}>
        <Space>{listEnquirylinks}</Space>
      </Col>
      <Col span={24}>
        <Outlet />
      </Col>
    </Row>
  );
};
