import { Col, Divider, Row } from "antd";

import { DynamicMetaTags } from "../utils/dynamicMetaTags";

import React from "react";
import { PageLayout } from "../components/layout/pageLayout";
import { Outlet } from "react-router-dom";
import { AdminNavBar } from "../components/navigation/adminNavBar";

export const LandingPage = () => {
  return (
    <PageLayout>
      <DynamicMetaTags ogTitle={`admin-propertySearch`} ogImage={""} />
      <Row style={{ maxWidth: "1200px", justifyContent: "center" }}>
        <AdminNavBar />
        <Divider />
        <Col span={24} style={{ marginTop: "5px" }}>
          <Outlet />
        </Col>
      </Row>
    </PageLayout>
  );
};
