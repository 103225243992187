import { Form, Input } from "antd";
import axios from "axios";

export const FormComponentCheckNumber = ({ required }) => {
  return (
    <Form.Item
      style={{ margin: "0px" }}
      label="contactNumber"
      name="contactNumber"
      rules={[
        {
          required: required,
          //   pattern: new RegExp("^[+]{1}(?:[0-9-()/.]s?){6, 15}[0-9]{1}$"),
          message: "contact number?",
        },
        {
          validator: async (_, value) => {
            await axios
              .get(".netlify/functions/checkNumber", {
                baseURL: "/",
                params: { contactNumber: value },
              })
              .then(function (response) {
                console.log(response.data);
                const check = response.data;
                if (!check) {
                  return Promise.resolve("Success!!");
                } else {
                  return Promise.reject("already exists");
                }
              });
          },
        },
      ]}
      hasFeedback
    >
      <Input onInput={(e) => (e.target.value = e.target.value.toLowerCase())} />
    </Form.Item>
  );
};
