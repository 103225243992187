import { Skeleton, Row, Col } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { OrganisationTable } from "./organisationTable";

export const ListOrganisations = () => {
  const [organisationData, setOrganisationData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getIntermediaryOrganisations = () => {
    setLoading(true);
    axios
      .get(".netlify/functions/getIntermediaryOrganisations", {
        baseURL: "/",
      })
      .then((response) => {
        setOrganisationData(response.data.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getIntermediaryOrganisations();
  }, []);

  return (
    <Row gutter={[18, 18]}>
      <Skeleton loading={loading}>
        <Col span={24}>
          <OrganisationTable organisationData={organisationData} />
        </Col>
      </Skeleton>
    </Row>
  );
};
