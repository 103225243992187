import { Form, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import instance from "../../utils/axiosConfig";

export const IntermediaryTypeDropdownUpdate = ({
  individualRef,
  label,
  name,
  required,
  message,
}) => {
  const [dropdownData, setDropDownData] = useState([]);

  const getIntermediaryType = () => {
    axios
      .get(".netlify/functions/getIntermediaryType", {
        baseURL: "/",
      })
      .then((response) => {
        setDropDownData(response.data.data);
      });
  };

  useEffect(() => {
    getIntermediaryType();
  }, []);

  const listOptions = dropdownData.map((data) => {
    return {
      value: data.ref,
      label: data.title,
    };
  });

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <Form.Item
      label={label}
      name="intermediaryType"
      rules={[
        {
          required: required,
          message: message,
        },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateIntermediaryIndividual", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  individualRef,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
      hasFeedback
    >
      <Select
        showSearch
        placeholder="Select a Intermediary Type"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={listOptions}
      />
    </Form.Item>
  );
};
