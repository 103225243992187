import { Col, Divider, Form, Row } from "antd";
import { useEffect } from "react";
import {
  InputFormItemIndividualUpdate,
  InputFormRadioUpdateIndividual,
} from "./formComponentsIntermediary";
import { IntermediaryTypeDropdownUpdate } from "./intermediaryTypeDropdownUpdate";
import { IntermediaryOrganisationsDropdownUpdate } from "./intermediaryOrganisationDropdownUpdate";

export const UpdateIndividualForm = ({ individualProfile }) => {
  const [form] = Form.useForm();
  console.log(individualProfile.ref);
  useEffect(() => {
    form.setFieldsValue({
      ...individualProfile,
    });
  }, [form, individualProfile]);

  return (
    <Form form={form} name="basic" layout="vertical" autoComplete="off">
      <Row gutter={[18, 18]}>
        <Col xs={24} sm={12} md={6}>
          <InputFormRadioUpdateIndividual
            individualRef={individualProfile.ref}
            label={"reviewed?"}
            name={"review"}
            required={true}
            message={"Have you reviewed the organisation"}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <InputFormRadioUpdateIndividual
            individualRef={individualProfile.ref}
            label={"Active?"}
            name={"active"}
            required={true}
            message={"Have you reviewed the organisation"}
          />
        </Col>
        <Divider style={{ margin: "0px" }} />
        <Col xs={12} sm={8} md={6}>
          <InputFormItemIndividualUpdate
            individualRef={individualProfile.ref}
            label={"Contact Name"}
            name={"contactName"}
            required={true}
            message={"Add company name"}
          />
        </Col>

        <Col xs={12} sm={8} md={6}>
          <InputFormItemIndividualUpdate
            individualRef={individualProfile.ref}
            label={"Contact Number"}
            name={"contactNumber"}
            required={true}
            message={"Add contact number"}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <InputFormItemIndividualUpdate
            individualRef={individualProfile.ref}
            label={"Email"}
            name={"email"}
            required={true}
            message={"Add email address"}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <IntermediaryTypeDropdownUpdate
            individualRef={individualProfile.ref}
            label={"Intermediary Type"}
            name={"intermediaryType"}
            required={true}
            message={"Add email address"}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <IntermediaryOrganisationsDropdownUpdate
            individualRef={individualProfile.ref}
            label={"Organisation"}
            name={"intermediaryOrganisation"}
            required={true}
            message={"Add email address"}
          />
        </Col>
      </Row>
    </Form>
  );
};
