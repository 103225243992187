import { TableOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Menu, Row } from "antd";

import React, { useState } from "react";
import { Link } from "react-router-dom";

export const AdminNavBar = () => {
  const items = [
    {
      label: (
        <Button type="link">
          <Link
            style={{
              color: "salmon",
              textDecoration: "none",
              fontSize: "15px",
            }}
            to="dashboard"
          >
            {"dashboard"}
          </Link>
        </Button>
      ),
      key: "dashboard",
    },
    {
      label: (
        <Link
          style={{
            color: "salmon",
            textDecoration: "none",
            fontSize: "15px",
          }}
          to="add-development"
        >
          {"development"}
        </Link>
      ),

      icon: <PlusCircleOutlined style={{ verticalAlign: "middle" }} />,
      key: "addDevelopment",
    },
    {
      label: (
        <Link
          style={{
            color: "salmon",
            textDecoration: "none",
            fontSize: "15px",
          }}
          to="development-db"
        >
          {"developmentDB"}
        </Link>
      ),
      key: "companyDB",
      icon: <TableOutlined style={{ verticalAlign: "middle" }} />,
    },
    {
      label: (
        <Link
          style={{
            color: "salmon",
            textDecoration: "none",
            fontSize: "15px",
          }}
          to="units"
        >
          {"units"}
        </Link>
      ),
      key: "addUnit",
      icon: <PlusCircleOutlined style={{ verticalAlign: "middle" }} />,
    },
    {
      label: (
        <Link
          style={{
            color: "salmon",
            textDecoration: "none",
            fontSize: "15px",
          }}
          to="/intermediary"
        >
          {"intermediary"}
        </Link>
      ),
      key: "intermediary",
      icon: <PlusCircleOutlined style={{ verticalAlign: "middle" }} />,
    },
    {
      label: (
        <Link
          style={{
            color: "salmon",
            textDecoration: "none",
            fontSize: "15px",
          }}
          to="/enquiries"
        >
          {"enquiries"}
        </Link>
      ),
      key: "enquiries",
      icon: <TableOutlined style={{ verticalAlign: "middle" }} />,
    },
  ];
  const [current, setCurrent] = useState("dashboard");
  const onClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <Row
      style={{
        position: "sticky",
        top: "60px",
        zIndex: "99999",
        width: "100%",
      }}
    >
      <Col span={24}>
        <Menu
          style={{
            // marginTop: "5px",
            color: "salmon",
            borderRadius: "5px",
          }}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </Col>
    </Row>
  );
};
