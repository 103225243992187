import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import instance from "../../utils/axiosConfig";

import { ListSaleUnitsTable } from "./listSaleUnitsTable";

export const ListSaleUnitsByDevelopment = ({ developmentId }) => {
  console.log(developmentId);

  const [saleData, setSaleData] = useState([]);
  console.log(saleData);

  useEffect(() => {
    instance
      .post(".netlify/functions/getSaleListingsByDevelopmentId", {
        developmentId,
      })
      .then(function (response) {
        setSaleData(response.data.unitsDocs.data);
      });
  }, [developmentId, setSaleData]);

  return (
    <Row gutter={[18, 18]}>
      <Col span={24}>
        <ListSaleUnitsTable listUnitsData={saleData} />
      </Col>
    </Row>
  );
};
