import "./App.css";

import { Routes, Route } from "react-router-dom";
import { NotFound } from "./utils/notFound";
import { LandingPage } from "./pages/LandingPage";
import { Assets } from "./pages/Assets";
import { Callback } from "./utils/auth0/callback";
import { AuthenticationGuard } from "./utils/auth0/authenticationGuard";
import { ProfilePage } from "./pages/Profile";

import { Auth0ContextProvider } from "./utils/auth0/auth0Context";
import PaymentElement from "./utils/stripe/paymentElement";
import Completion from "./utils/stripe/completion";
import { AddDevelopementForm } from "./components/development/addDevelopmentForm";
import { Dashboard } from "./pages/Dashboard";
import { Units } from "./pages/Units";
import { DevelopmentDb } from "./pages/DevelopmentDb";

import { AddUnit } from "./components/units/addUnit";

// Intermediary
import { Intermediary } from "./pages/Intermediary";
import { AddOrganisation } from "./components/intermediary/addOrganisation";
import { ListOrganisations } from "./components/intermediary/listOrganisations";
import { AddIndividual } from "./components/intermediary/addIndividual";
import { ListIndividuals } from "./components/intermediary/listIndividuals";

// Enquiries
import { Enquiries } from "./pages/Enquiries";
import { ListEnquiries } from "./components/enquiries/listEnquiries";
import { ListUnitDb } from "./components/units/listUnitDb";

function App() {
  return (
    <Auth0ContextProvider>
      <Routes>
        <Route
          exact
          path="/"
          element={<AuthenticationGuard component={LandingPage} />}
        >
          <Route index element={<Dashboard />} />
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route
            exact
            path="add-development"
            element={<AddDevelopementForm />}
          />
          <Route exact path="development-db" element={<DevelopmentDb />} />
          <Route exact path="units" element={<Units />}>
            <Route path="add" element={<AddUnit />} />
            <Route path="list" element={<ListUnitDb />} />
          </Route>
          <Route exact path="intermediary" element={<Intermediary />}>
            <Route path="add-organisation" element={<AddOrganisation />} />
            <Route path="list-organisations" element={<ListOrganisations />} />
            <Route path="add-individual" element={<AddIndividual />} />
            <Route path="list-individuals" element={<ListIndividuals />} />
          </Route>
          <Route exact path="enquiries" element={<Enquiries />}>
            <Route path="list-enquiries" element={<ListEnquiries />} />
          </Route>
        </Route>

        <Route
          path="/assets"
          element={<AuthenticationGuard component={Assets} />}
        />
        <Route
          path="/profile"
          element={<AuthenticationGuard component={ProfilePage} />}
        />
        <Route
          path="/checkout"
          element={<AuthenticationGuard component={PaymentElement} />}
        />
        <Route
          path="/completion"
          element={<AuthenticationGuard component={Completion} />}
        />
        <Route exact path="/*" element={<NotFound />} />
        <Route exact path="/callback" element={<Callback />} />
      </Routes>
    </Auth0ContextProvider>
  );
}

export default App;
