import { Button, Card, Col, Form, Input, InputNumber, Radio, Row } from "antd";
import instance from "../../utils/axiosConfig";
import { PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export const InputFormItemUnits = ({
  label,
  name,
  required,
  message,
  placeholder,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};
export const InputFormNumberUnits = ({
  label,
  name,
  required,
  message,
  min,
  max,
  defaultValue,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <InputNumber
        style={{
          width: "100%",
        }}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        min={min}
        max={max}
        defaultValue={defaultValue}
      />
    </Form.Item>
  );
};

export const InputFormNumberGeneral = ({
  label,
  name,
  required,
  message,
  min,
  max,
  defaultValue,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <InputNumber
        style={{
          width: "100%",
        }}
        min={min}
        max={max}
        defaultValue={defaultValue}
      />
    </Form.Item>
  );
};
export const InputFormItemUnitsEdit = ({ label, name, required, message }) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
      hasFeedback
    >
      <Input />
    </Form.Item>
  );
};
export const InputFormItemRadioYesNo = ({ label, name, required, message }) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <Radio.Group checked={false} buttonStyle="solid">
        <Radio.Button value={true}>{"Yes"}</Radio.Button>
        <Radio.Button value={false}>{"No"}</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

export const InputFormItemTextAreaUnits = ({
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <TextArea rows={4} />
    </Form.Item>
  );
};
export const InputFormItemTextAreaUnitsEdit = ({
  developmentId,
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[
        { required: required, message: message },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateDevelopment", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  developmentId: developmentId,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
      hasFeedback
    >
      <TextArea rows={4} />
    </Form.Item>
  );
};

export const ImagesFormList = () => {
  return (
    <Form.List name="images">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Card>
              <Row key={key} gutter={[18, 18]}>
                <Col span={24}>
                  <Form.Item
                    {...restField}
                    name={[name, "url"]}
                    style={{ width: "100%", margin: "0px" }}
                    rules={[
                      {
                        required: true,
                        type: "url",
                        message: "add a image URL",
                      },
                    ]}
                  >
                    <Input placeholder="Image URL" />
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item
                    {...restField}
                    style={{ width: "100%", margin: "0px" }}
                    name={[name, "description"]}
                    rules={[
                      {
                        required: true,
                        message: "add image description",
                      },
                    ]}
                  >
                    <Input placeholder="image description" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button onClick={() => remove(name)}>Delete</Button>
                </Col>
              </Row>
            </Card>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              style={{ marginTop: "10px" }}
              icon={<PlusOutlined />}
            >
              Add Image
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export const InputFormItemUnitsUpdate = ({
  unitId,
  label,
  name,
  required,
  message,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        style={{ margin: "0px" }}
        name={name}
        rules={[
          { required: required, message: message },
          {
            validator: async (_, value) => {
              await instance
                .post(".netlify/functions/updateUnit", {
                  baseURL: "/",
                  params: {
                    data: { [name]: value },
                    unitId: unitId,
                  },
                })

                .then(function (response) {
                  if (response.status === 200) {
                    return Promise.resolve("success");
                  } else {
                    return Promise.reject(new Error("error"));
                  }
                });
            },
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
    </>
  );
};

export const InputFormItemNumberUnitsUpdate = ({
  unitId,
  label,
  name,
  required,
  message,
  min,
  max,
  defaultValue,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        style={{ margin: "0px" }}
        name={name}
        rules={[
          { required: required, message: message },
          {
            validator: async (_, value) => {
              await instance
                .post(".netlify/functions/updateUnit", {
                  baseURL: "/",
                  params: {
                    data: { [name]: value },
                    unitId: unitId,
                  },
                })

                .then(function (response) {
                  if (response.status === 200) {
                    return Promise.resolve("success");
                  } else {
                    return Promise.reject(new Error("error"));
                  }
                });
            },
          },
        ]}
        hasFeedback
      >
        <InputNumber
          style={{
            width: "100%",
          }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={min}
          max={max}
          defaultValue={defaultValue}
        />
      </Form.Item>
    </>
  );
};
export const InputFormItemNumberUnitRentUpdate = ({
  unitId,
  label,
  name,
  required,
  message,
  min,
  max,
  defaultValue,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        style={{ margin: "0px" }}
        name={name}
        rules={[
          { required: required, message: message },
          {
            validator: async (_, value) => {
              await instance
                .post(".netlify/functions/updateRentUnit", {
                  baseURL: "/",
                  params: {
                    data: { [name]: value },
                    unitId: unitId,
                  },
                })

                .then(function (response) {
                  if (response.status === 200) {
                    return Promise.resolve("success");
                  } else {
                    return Promise.reject(new Error("error"));
                  }
                });
            },
          },
        ]}
        hasFeedback
      >
        <InputNumber
          style={{
            width: "100%",
          }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={min}
          max={max}
          defaultValue={defaultValue}
        />
      </Form.Item>
    </>
  );
};

export const InputFormItemIntermediaryUpdate = ({
  intermediaryRef,
  label,
  name,
  required,
  message,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        style={{ margin: "0px" }}
        name={name}
        rules={[
          { required: required, message: message },
          {
            validator: async (_, value) => {
              await instance
                .post(".netlify/functions/updateIntermediary", {
                  baseURL: "/",
                  params: {
                    data: { [name]: value },
                    intermediaryRef,
                  },
                })

                .then(function (response) {
                  if (response.status === 200) {
                    return Promise.resolve("success");
                  } else {
                    return Promise.reject(new Error("error"));
                  }
                });
            },
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
    </>
  );
};

export const InputFormItemRentRadioYesNoUpdate = ({
  unitId,
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[
        { required: required, message: message },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateRentUnit", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  unitId: unitId,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
    >
      <Radio.Group checked={false} buttonStyle="solid">
        <Radio.Button value={true}>{"Yes"}</Radio.Button>
        <Radio.Button value={false}>{"No"}</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

export const InputFormItemUnitsRentUpdate = ({
  unitId,
  label,
  name,
  required,
  message,
}) => {
  return (
    <>
      <Form.Item
        label={label}
        style={{ margin: "0px" }}
        name={name}
        rules={[
          { required: required, message: message },
          {
            validator: async (_, value) => {
              await instance
                .post(".netlify/functions/updateRentUnit", {
                  baseURL: "/",
                  params: {
                    data: { [name]: value },
                    unitId: unitId,
                  },
                })

                .then(function (response) {
                  if (response.status === 200) {
                    return Promise.resolve("success");
                  } else {
                    return Promise.reject(new Error("error"));
                  }
                });
            },
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
    </>
  );
};
