import { Col, Row, Skeleton, Space, Statistic, Tabs } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { DevelopmentDbTable } from "../components/developmentDb/developmentDbTable";
import { DevelopmentDbTableList } from "../components/developmentDb/developmentDbTableList";

export const DevelopmentDb = () => {
  const [developmentDb, setDevelopmentDb] = useState([]);
  const [developmentStats, setDevelopmentStats] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDevelopmentDb = () => {
    axios
      .get(".netlify/functions/getDevelopmentDb", {
        baseURL: "/",
      })
      .then((response) => {
        setDevelopmentDb(response.data.developmentDb);
        setDevelopmentStats(response.data.stats);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDevelopmentDb();
  }, []);

  const items = [
    {
      key: "1",
      label: "Search",
      children: (
        <Skeleton loading={loading} style={{ width: "1400px" }}>
          <DevelopmentDbTable developmentDb={developmentDb.data} />
        </Skeleton>
      ),
    },
    {
      key: "2",
      label: "List",
      children: (
        <div style={{ margin: "10px" }}>
          <Skeleton loading={loading} style={{ width: "1400px" }}>
            <DevelopmentDbTableList developmentDb={developmentDb.data} />
          </Skeleton>
        </div>
      ),
    },
  ];

  return (
    <div style={{ margin: "10px" }}>
      <Row style={{ textAlign: "center" }}>
        <Col span={24}>
          <Space>
            <Statistic
              title="Developments"
              value={developmentStats.developmentCount}
            />
            <Statistic
              title="Sum Units"
              value={developmentStats.totalUnitCount}
            />
            <Statistic
              title="Rental Units"
              value={developmentStats.rentalCount}
            />
            <Statistic title="Sale Units" value={developmentStats.saleCount} />
          </Space>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};
