import { Button, Card, Col, Divider, Form, Input, Row, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import instance from "../../utils/axiosConfig";
import {
  InputFormItem,
  InputFormItemTextArea,
} from "../developmentDb/formComponents";
import {
  InputFormNumberGeneral,
  InputFormNumberUnits,
} from "../units/formComponentsUnits";
import { AddDevelopmentNameModal } from "./addDevelopmentNameModal";
import { CitiesFormDropdown } from "../developmentDb/citiesFormDropdown";
export const AddDevelopementForm = () => {
  const postDevelopment = async (developmentData) => {
    console.log(developmentData);
    return instance
      .post(".netlify/functions/postDevelopment", {
        developmentData,
      })
      .then(function (response) {
        message.success({
          content: `Create Successful`,
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        console.log(response);
      });
  };

  const onFinish = (values) => {
    postDevelopment(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      initialValues={{}}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={[18, 18]}>
        <Col xs={12} sm={12} md={4}>
          <InputFormItem
            label={"Development Name"}
            name={"developmentName"}
            required={true}
            message={"Enter the name of the development"}
          />
          <AddDevelopmentNameModal />
        </Col>
        <Col xs={12} sm={12} md={4}>
          <InputFormItem
            label={"Developer"}
            name={"developer"}
            required={true}
            message={"Enter the name of the development company"}
          />
        </Col>
        <Col xs={12} sm={12} md={4}>
          <InputFormNumberUnits
            label={"#of Floors"}
            name={"floors"}
            required={true}
            message={"Enter the number of floors in the development"}
          />
        </Col>
        <Col xs={12} sm={12} md={4}>
          <InputFormItem
            label={"Development Type"}
            name={"developmentType"}
            required={true}
            message={"Enter type of development"}
          />
        </Col>
        <Col xs={12} sm={12} md={4}>
          <InputFormNumberGeneral
            label={"Year of Completion"}
            name={"completionYear"}
            required={true}
            message={"Year the development was completed"}
          />
        </Col>
        <Col xs={12} sm={12} md={4}>
          <InputFormNumberUnits
            label={"Total Number of Units"}
            name={"totalUnits"}
            required={true}
            message={"Year the development was completed"}
          />
        </Col>
        <Col xs={12} sm={12} md={4}>
          <InputFormItem
            label={"Tenure"}
            name={"tenure"}
            required={true}
            message={"example: Freehold , 99 year lease"}
          />
        </Col>
        <Col xs={12} sm={12} md={4}>
          <InputFormNumberUnits
            label={"Service Charge"}
            name={"serviceCharge"}
            required={false}
            message={"monthly service charge per spqft"}
          />
        </Col>
        <Col xs={12} sm={12} md={4}>
          <InputFormNumberUnits
            label={"Sinking Fund"}
            name={"sinikingFund"}
            required={false}
            message={"sinking fund contribution"}
          />
        </Col>
        <Col xs={12} sm={12} md={8}>
          <InputFormItem
            label={"Address"}
            name={"address"}
            required={true}
            message={"sinking fund contribution"}
          />
        </Col>
        <Col xs={12} sm={12} md={12}>
          <CitiesFormDropdown />
        </Col>
        <Col xs={12} sm={12} md={12}>
          <InputFormItem
            label={"Google Location"}
            name={"googleLocation"}
            required={true}
            type={"url"}
            message={"get shared location from embed"}
            placeholder={"google https:// location"}
          />
        </Col>
        <Col xs={12} sm={12} md={6}>
          <InputFormNumberGeneral
            label={"Latitude"}
            name={"latitude"}
            required={true}
            type={""}
            message={"enter Latitude"}
            placeholder={"starts with 7"}
          />
        </Col>
        <Col xs={12} sm={12} md={6}>
          <InputFormNumberGeneral
            label={"Longitude"}
            name={"longitude"}
            required={true}
            type={"url"}
            message={"enter longitude"}
            placeholder={"starts with "}
          />
        </Col>

        <Col xs={24} sm={24} md={12}>
          <InputFormItemTextArea
            label={"Description"}
            name={"description"}
            required={true}
            type={""}
            message={"enter a description"}
            placeholder={"starts with "}
          />
        </Col>
        <Divider />
        <Col xs={24} sm={24} md={24}>
          <Form.List name="images">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Card>
                    <Row key={key} gutter={[18, 18]}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "url"]}
                          style={{ width: "100%", margin: "0px" }}
                          rules={[
                            {
                              required: true,
                              type: "url",
                              message: "add a image URL",
                            },
                          ]}
                        >
                          <Input placeholder="Image URL" />
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item
                          {...restField}
                          style={{ width: "100%", margin: "0px" }}
                          name={[name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "add image description",
                            },
                          ]}
                        >
                          <Input placeholder="image description" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button onClick={() => remove(name)}>Delete</Button>
                      </Col>
                    </Row>
                  </Card>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    style={{ marginTop: "10px" }}
                    icon={<PlusOutlined />}
                  >
                    Add Image
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.List name="documents">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Card>
                    <Row key={key} gutter={[18, 18]}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "url"]}
                          style={{ width: "100%", margin: "0px" }}
                          rules={[
                            {
                              required: true,
                              type: "url",
                              message: "add a document URL",
                            },
                          ]}
                        >
                          <Input placeholder="document URL" />
                        </Form.Item>
                      </Col>

                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          style={{ width: "100%", margin: "0px" }}
                          name={[name, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "add document title",
                            },
                          ]}
                        >
                          <Input placeholder="document title" />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          style={{ width: "100%", margin: "0px" }}
                          name={[name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "add document description",
                            },
                          ]}
                        >
                          <Input placeholder="document description" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button onClick={() => remove(name)}>Delete</Button>
                      </Col>
                    </Row>
                  </Card>
                ))}
                <Form.Item style={{ marginTop: "10px" }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Document
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
