import { Form, Input, InputNumber } from "antd";
import instance from "../../utils/axiosConfig";

const { TextArea } = Input;

export const InputFormItem = ({ label, name, required, message }) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <Input />
    </Form.Item>
  );
};

export const InputFormItemTextArea = ({ label, name, required, message }) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[{ required: required, message: message }]}
    >
      <TextArea rows={4} />
    </Form.Item>
  );
};

export const InputFormItemUpdate = ({
  developmentId,
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[
        { required: required, message: message },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateDevelopment", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  developmentId: developmentId,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
      hasFeedback
    >
      <Input />
    </Form.Item>
  );
};

export const InputFormNumbersUpdate = ({
  developmentId,
  label,
  name,
  required,
  message,
  min,
  max,
  defaultValue,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[
        { required: required, message: message },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateDevelopment", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  developmentId: developmentId,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
    >
      <InputNumber
        style={{
          width: "100%",
        }}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        min={min}
        max={max}
        defaultValue={defaultValue}
      />
    </Form.Item>
  );
};
export const InputFormNumbersGeneralUpdate = ({
  developmentId,
  label,
  name,
  required,
  message,
  min,
  max,
  defaultValue,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[
        { required: required, message: message },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateDevelopment", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  developmentId: developmentId,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
    >
      <InputNumber
        style={{
          width: "100%",
        }}
        min={min}
        max={max}
        defaultValue={defaultValue}
      />
    </Form.Item>
  );
};

export const InputFormItemTextAreaUpdate = ({
  developmentId,
  label,
  name,
  required,
  message,
}) => {
  return (
    <Form.Item
      label={label}
      style={{ margin: "0px" }}
      name={name}
      rules={[
        { required: required, message: message },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateDevelopment", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  developmentId: developmentId,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
      hasFeedback
    >
      <TextArea rows={4} />
    </Form.Item>
  );
};
