import { Button, Col, Row, Table } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
export const EnquiriesTable = ({ enquiryData }) => {
  const columns = [
    {
      title: "Reviewed",
      dataIndex: "review",
      key: "date",
      render: (text, record) =>
        text ? (
          <CheckCircleTwoTone style={{ color: "greens" }} />
        ) : (
          <CloseCircleTwoTone style={{ color: "greens" }} />
        ),
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "date",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Development",
      dataIndex: "developmentName",
      key: "developement",
    },
    {
      title: "Bedrooms",
      dataIndex: "bedrooms",
      key: "bedrooms",
    },
    {
      title: "bathrooms",
      dataIndex: "bathrooms",
      key: "bathrooms",
    },
    {
      title: "Contact",
      dataIndex: "contactNumber",
      key: "contactName",
      render: (text, record) =>
        record.whatsApp ? (
          <Button
            rel="noreferrer"
            target="_blank"
            href={`https://wa.me/${text}`}
            type="link"
            style={{ margin: "0px", padding: "0px" }}
          >
            {text}
          </Button>
        ) : (
          <>{text}</>
        ),
    },
  ];

  return (
    <Row gutter={[18, 18]}>
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={enquiryData}
          expandable={{
            expandedRowRender: (record) => (
              <p
                style={{
                  margin: 0,
                }}
              >
                {record.message}
              </p>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
        />
      </Col>
    </Row>
  );
};
