import { Card, Col, Form, Row, Button, Input, Image } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import instance from "../../utils/axiosConfig";

export const FormComponentImagesSaleUpdate = ({ unitData }) => {
  const [progress, setProgress] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...unitData,
    });
  }, [form, unitData]);

  const onFinish = (values) => {
    updateDevelopment(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const updateDevelopment = async (imageData) => {
    setProgress(true);
    await instance
      .post(".netlify/functions/updateUnit", {
        baseURL: "/",
        params: {
          data: imageData,
          unitId: unitData.id,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          return Promise.resolve(setProgress(false));
        } else {
          return Promise.reject(new Error("error"));
        }
      });
  };

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allValues) => {
          updateDevelopment(allValues);
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.List name={"images"}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Card style={{ marginBottom: "5px" }}>
                  <Row key={key} gutter={[18, 18]}>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "url"]}
                        style={{ width: "100%", margin: "0px" }}
                        rules={[
                          {
                            required: true,
                            type: "url",
                            message: "add a image URL",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="Image URL" />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <Form.Item
                        {...restField}
                        style={{ width: "100%", margin: "0px" }}
                        name={[name, "description"]}
                        rules={[
                          {
                            required: true,
                            message: "add image description",
                          },
                        ]}
                      >
                        <Input placeholder="image description" />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Button
                        danger
                        onClick={() => {
                          remove(name);
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                    <Col span={2}>
                      <Image src={form.getFieldValue(["images"])[key]?.url} />
                    </Col>
                  </Row>
                </Card>
              ))}
              <Form.Item>
                <Row gutter={[18, 18]}>
                  <Col span={20}>
                    <Button
                      type="dashed"
                      block
                      onClick={() => add()}
                      style={{ marginTop: "10px" }}
                      icon={<PlusOutlined />}
                    >
                      Add Image
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="primary"
                      style={{ marginTop: "10px" }}
                      block
                      loading={progress}
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Card>
  );
};
