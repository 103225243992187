import { Button, Col, Form, Row, message } from "antd";
import { DevelopmentDropdown } from "./developmentDropdown";
import instance from "../../utils/axiosConfig";
import {
  ImagesFormList,
  InputFormItemTextAreaUnits,
  InputFormItemUnits,
  InputFormNumberUnits,
} from "./formComponentsUnits";
import { useContext, useEffect } from "react";
import Auth0Context from "../../utils/auth0/auth0Context";
import { PropertyTypeDropdown } from "./propertyTypeDropdown";
import { TransactionTypeDropdown } from "./transactionTypeDropdown";

export const AddUnitForm = ({ contactInfo, setContactInfo }) => {
  const { userProfile } = useContext(Auth0Context);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      contactPersonId: contactInfo,
    });
  }, [form, contactInfo]);

  const onFinish = (values) => {
    postUnit(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const postContactInfo = contactInfo.map((data) => {
    return {
      contactName: data.label,
      contactRef: data.value,
    };
  });

  const postUnit = async (unitData) => {
    console.log(unitData);
    return instance
      .post(".netlify/functions/postUnit", {
        unitData,
        userProfile,
        postContactInfo,
      })
      .then(function (response) {
        message.success({
          content: `Create Successful`,
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        console.log(response);
        form.resetFields();
        setContactInfo([]);
      });
  };

  const listContactInfo = contactInfo.map((data) => {
    return <Button disabled>{data.label}</Button>;
  });

  console.log(contactInfo);

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={[18, 18]} className="main" style={{ margin: "10px" }}>
        <Col span={24}>{listContactInfo}</Col>
        <Col xs={24} sm={24} md={6}>
          <DevelopmentDropdown />
        </Col>
        <Col xs={24} sm={24} md={4}>
          <PropertyTypeDropdown />
        </Col>
        <Col xs={24} sm={24} md={4}>
          <TransactionTypeDropdown defaultValue={"385732311780425792"} />
        </Col>
        <Col xs={24} sm={24} md={10}>
          <InputFormItemUnits
            label={""}
            name={"sourceUrl"}
            required={true}
            message={"Enter Source"}
          />
          SourceUrl
        </Col>
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={2}>
            <InputFormNumberUnits
              label={"Bedrooms"}
              name={"bedrooms"}
              required={true}
              message={"enter number of Bedroom"}
            />
          </Col>
          <Col xs={24} sm={24} md={2}>
            <InputFormNumberUnits
              label={"Bathrooms"}
              name={"bathrooms"}
              required={true}
              message={"enter number of Bedroom"}
            />
          </Col>
          <Col xs={24} sm={24} md={2}>
            <InputFormNumberUnits
              label={"squareFeet"}
              name={"squareFeet"}
              required={true}
              message={"enter number of Bedroom"}
            />
          </Col>
          <Col xs={24} sm={24} md={2}>
            <InputFormNumberUnits
              label={"#Floor"}
              name={"floor"}
              required={true}
              message={"enter number of Bedroom"}
            />
          </Col>
          <Col xs={24} sm={24} md={5} style={{ width: "200px" }}>
            <InputFormNumberUnits
              label={"Price"}
              name={"price"}
              required={true}
              message={"enter number of Bedroom"}
            />
          </Col>
          <Col xs={24} sm={24} md={24}>
            <InputFormItemTextAreaUnits
              label={"Description"}
              name={"description"}
              required={true}
              message={"enter number of Bedroom"}
            />
          </Col>
          <Col xs={24} sm={24} md={24}>
            <ImagesFormList />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <InputFormItemUnits
              label={"YouTube Url"}
              name={"youTubeUrl"}
              required={false}
              message={"enter youTubeUrl"}
            />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <InputFormItemUnits
              label={"Vimeo Url"}
              name={"vimeoUrl"}
              required={false}
              message={"enter VimeoUrl"}
            />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <InputFormItemUnits
              label={"Virtual Tour"}
              name={"matterportUrl"}
              required={false}
              message={"enter Matterport Url"}
            />
          </Col>
        </Row>
        <br></br>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
