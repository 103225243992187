import { Form, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { AddOrganisationModalForm } from "./addOrganisationModalForm";

export const IntermediaryOrganisationsDropdown = () => {
  const [dropdownData, setDropDownData] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const getIntermediaryOrganisations = () => {
    setLoading(true);
    axios
      .get(".netlify/functions/getIntermediaryOrganisations", {
        baseURL: "/",
      })
      .then((response) => {
        setDropDownData(response.data.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getIntermediaryOrganisations();
  }, [reload]);

  const listOptions = dropdownData.map((data) => {
    return {
      value: data.ref,
      label: data.companyName,
    };
  });

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <>
      <Form.Item
        label="Organisation"
        name="intermediaryOrganisation"
        style={{ margin: "0px" }}
        rules={[
          {
            required: true,
            message: "Select a Organisation",
          },
        ]}
      >
        <Select
          loading={loading}
          showSearch
          placeholder="Select a Organisation"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          options={listOptions}
        />
      </Form.Item>
      <AddOrganisationModalForm reload={reload} setReload={setReload} />
    </>
  );
};
