import { Col, Row, Button, Space } from "antd";
import { Link, Outlet } from "react-router-dom";

export const Intermediary = () => {
  const intermediaryLinks = [
    {
      title: "Add Organisation",
      url: "add-organisation",
    },
    {
      title: "List Organisations",
      url: "list-organisations",
    },
    {
      title: "Add Individual",
      url: "add-individual",
    },
    {
      title: "List Individual",
      url: "list-individuals",
    },
  ];

  const listIntermediaryLinks = intermediaryLinks.map((data) => {
    return (
      <Col xs={24} sm={24} md={4} style={{ textAlign: "center" }}>
        <Link to={data.url}>
          <Button>{data.title}</Button>
        </Link>
      </Col>
    );
  });

  return (
    <Row gutter={[18, 18]}>
      <Col span={24} style={{ textAlign: "center" }}>
        <Space>{listIntermediaryLinks}</Space>
      </Col>
      <Col span={24}>
        <Outlet />
      </Col>
    </Row>
  );
};
