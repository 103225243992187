import { Button, Card, Col, Form, Row, message, Radio } from "antd";

import instance from "../../utils/axiosConfig";

import { InputFormItemUnits } from "./formComponentsUnits";
import { useContext } from "react";
import Auth0Context from "../../utils/auth0/auth0Context";
import { IntermediaryTypeDropdown } from "./intermediaryTypeDropdown";
import { IntermediaryOrganisationsDropdown } from "./intermediaryOrganisationDropdown";
import { FormComponentCheckNumber } from "./formComponentCheckNumber";

export const AddContactForm = ({ setContactInfo }) => {
  const { userProfile } = useContext(Auth0Context);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    postIndividualData(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const postIndividualData = async (individualData) => {
    return instance
      .post(".netlify/functions/postIndividual", {
        individualData,
        creatorProfile: userProfile,
      })
      .then(function (response) {
        message.success({
          content: `Create Successful`,
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        console.log(response);
        setContactInfo([
          {
            label: response.data.data.contactName,
            value: response.data.ref["@ref"].id,
          },
        ]);
        form.resetFields();
      });
  };

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={[18, 18]} className="main" style={{ margin: "10px" }}>
        <Col span={24}>
          <Card title={"Add Contact Information"}>
            <Row gutter={[18, 18]}>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <IntermediaryTypeDropdown />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <IntermediaryOrganisationsDropdown />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <InputFormItemUnits
                  label={"Contact Name"}
                  name={"contactName"}
                  required={true}
                  message={"enter number of Bedroom"}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={4}>
                <InputFormItemUnits
                  label={"Contact Number"}
                  name={"contactNumber"}
                  required={true}
                  message={"enter number of Bedroom"}
                />
              </Col> */}
              <Col xs={24} sm={24} md={4}>
                <FormComponentCheckNumber required={true} />
              </Col>
              <Col xs={24} sm={24} md={4}>
                <Form.Item
                  label={"whatsApp?"}
                  style={{ margin: "0px" }}
                  name={"whatsApp"}
                  rules={[{ required: true, message: "whatsApp?" }]}
                >
                  <Radio.Group defaultValue={false} buttonStyle="solid">
                    <Radio.Button value={true}>{"Yes"}</Radio.Button>
                    <Radio.Button value={false}>{"No"}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <InputFormItemUnits
                  label={"Email"}
                  name={"email"}
                  required={false}
                  message={"enter number of Bedroom"}
                />
              </Col>
              <br></br>
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};
