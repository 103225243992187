import { Col, Divider, Form, Row } from "antd";
import { useEffect } from "react";
import {
  InputFormItemIntermediaryUpdate,
  InputFormRadioUpdate,
} from "./formComponentsIntermediary";

export const UpdateOraginsationForm = ({ organisationProfile }) => {
  const [form] = Form.useForm();
  console.log(organisationProfile.ref);
  useEffect(() => {
    form.setFieldsValue({
      ...organisationProfile,
    });
  }, [form, organisationProfile]);

  return (
    <Form form={form} name="basic" layout="vertical" autoComplete="off">
      <Row gutter={[18, 18]}>
        <Col xs={24} sm={12} md={6}>
          <InputFormRadioUpdate
            organisationRef={organisationProfile.ref}
            label={"reviewed?"}
            name={"review"}
            required={true}
            message={"Have you reviewed the organisation"}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <InputFormRadioUpdate
            organisationRef={organisationProfile.ref}
            label={"Active?"}
            name={"active"}
            required={true}
            message={"Have you reviewed the organisation"}
          />
        </Col>
        <Divider style={{ margin: "0px" }} />
        <Col xs={12} sm={8} md={6}>
          <InputFormItemIntermediaryUpdate
            organisationRef={organisationProfile.ref}
            label={"Organisation Name"}
            name={"companyName"}
            required={true}
            message={"Add company name"}
          />
        </Col>
        <Col xs={12} sm={8} md={14}>
          <InputFormItemIntermediaryUpdate
            organisationRef={organisationProfile.ref}
            label={"Address"}
            name={"address"}
            required={true}
            message={"Add company name"}
          />
        </Col>
        <Col xs={12} sm={8} md={10}>
          <InputFormItemIntermediaryUpdate
            organisationRef={organisationProfile.ref}
            label={"Website URL"}
            name={"websiteUrl"}
            required={true}
            message={"Add website url"}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <InputFormItemIntermediaryUpdate
            organisationRef={organisationProfile.ref}
            label={"Contact Number"}
            name={"contactNumber"}
            required={true}
            message={"Add contact number"}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <InputFormItemIntermediaryUpdate
            organisationRef={organisationProfile.ref}
            label={"Email"}
            name={"email"}
            required={true}
            message={"Add email address"}
          />
        </Col>
        <Col xs={12} sm={8} md={10}>
          <InputFormItemIntermediaryUpdate
            organisationRef={organisationProfile.ref}
            label={"Company Logo - 32px"}
            name={"companyLogo"}
            required={true}
            message={"a company Logo"}
          />
        </Col>
      </Row>
    </Form>
  );
};
