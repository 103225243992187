import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import instance from "../../utils/axiosConfig";
import { ListRentUnitsTable } from "./listRentUnitsTable";

export const ListRentUnitsByDevelopment = ({ developmentId }) => {
  console.log(developmentId);

  const [rentData, setRentData] = useState([]);
  console.log(rentData);

  useEffect(() => {
    instance
      .post(".netlify/functions/getRentListingsByDevelopmentId", {
        developmentId,
      })
      .then(function (response) {
        setRentData(response.data.unitsDocs.data);
      });
  }, [developmentId, setRentData]);

  return (
    <Row gutter={[18, 18]}>
      <Col span={24}>
        <ListRentUnitsTable listUnitsData={rentData} />
      </Col>
    </Row>
  );
};
