import React, { useContext, useState } from "react";
import { Button, Form, Input, Modal, message } from "antd";
import Auth0Context from "../../utils/auth0/auth0Context";
import instance from "../../utils/axiosConfig";
import {
  InputFormNumberGeneral,
  InputFormNumberUnits,
} from "../units/formComponentsUnits";
import { CitiesFormDropdown } from "../developmentDb/citiesFormDropdown";
const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={open}
      style={{
        top: 200,
      }}
      title="Add Development"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="developmentName"
          label="developmentName"
          rules={[
            {
              required: true,
              message: "Please input development name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <CitiesFormDropdown />
        <InputFormNumberGeneral
          label={"Year of Completion"}
          name={"completionYear"}
          required={true}
          message={"Year the development was completed"}
        />

        <InputFormNumberUnits
          label={"Total Number of Units"}
          name={"totalUnits"}
          required={true}
          message={"Year the development was completed"}
        />
        <InputFormNumberUnits
          label={"#of Floors"}
          name={"floors"}
          required={true}
          message={"Enter the number of floors in the development"}
        />
      </Form>
    </Modal>
  );
};
export const AddDevelopmentNameModal = ({ reload, setReload }) => {
  const { userProfile } = useContext(Auth0Context);
  const [open, setOpen] = useState(false);

  const postDevelopment = async (developmentData) => {
    console.log(developmentData);
    return instance
      .post(".netlify/functions/postDevelopment", {
        developmentData,
        creatorProfile: userProfile,
      })
      .then(function (response) {
        message.success({
          content: `Create Successful`,
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        setReload(!reload);
      });
  };

  const onCreate = (values) => {
    postDevelopment(values);
    setOpen(false);
  };

  return (
    <div>
      <Button
        type="link"
        onClick={() => {
          setOpen(true);
        }}
      >
        <small>{"add development"}</small>
      </Button>
      <CollectionCreateForm
        open={open}
        onCreate={onCreate}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
