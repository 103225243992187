import { Form, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

export const TransactionTypeDropdown = ({ defaultValue }) => {
  const [dropdownData, setDropDownData] = useState([]);

  const getTransactionType = () => {
    axios
      .get(".netlify/functions/getTransactionType", {
        baseURL: "/",
      })
      .then((response) => {
        setDropDownData(response.data.data);
      });
  };

  useEffect(() => {
    getTransactionType();
  }, []);

  const listOptions = dropdownData.map((data) => {
    return {
      value: data.ref,
      label: data.title,
    };
  });

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <Form.Item
      label=""
      name="transactionType"
      rules={[
        {
          required: true,
          message: "Select a Transaction Type",
        },
      ]}
      initialValue={defaultValue}
    >
      <Select
        showSearch
        placeholder="Select a Property Type"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={listOptions}
      />
    </Form.Item>
  );
};
