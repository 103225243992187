import { Col, Row } from "antd";
import { IntermediaryOrganisationsDropdown } from "../units/intermediaryOrganisationDropdown";
import { AddOraginsationForm } from "./addOrgnisationForm";

export const AddOrganisation = () => {
  return (
    <Row gutter={[18, 18]}>
      <div style={{ textAlign: "center" }}>
        <IntermediaryOrganisationsDropdown />
      </div>
      <Col span={24}>
        <AddOraginsationForm />
      </Col>
    </Row>
  );
};
