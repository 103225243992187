import { Form, Radio } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import instance from "../../utils/axiosConfig";

export const FormInputRadioFurnishingStatusUpdate = ({ unitId, name }) => {
  const [radioData, setRadioData] = useState([]);
  const getFurnishingStatus = () => {
    axios
      .get(".netlify/functions/getFurnishingStatus", {
        baseURL: "/",
      })
      .then((response) => {
        setRadioData(response.data.data);
      });
  };

  useEffect(() => {
    getFurnishingStatus();
  }, []);

  const onChange = ({ target: { value } }) => {
    console.log("radio1 checked", value);
  };

  const listRadioData = radioData.map((data) => {
    return {
      label: data.title,
      value: data.ref,
    };
  });

  return (
    <Form.Item
      label="Furnishing Status"
      name="furnishingStatus"
      rules={[
        {
          required: true,
          message: "Select a the furnishing status",
        },
        {
          validator: async (_, value) => {
            await instance
              .post(".netlify/functions/updateRentUnit", {
                baseURL: "/",
                params: {
                  data: { [name]: value },
                  unitId: unitId,
                },
              })

              .then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve("success");
                } else {
                  return Promise.reject(new Error("error"));
                }
              });
          },
        },
      ]}
    >
      <Radio.Group
        options={listRadioData}
        onChange={onChange}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>
  );
};
