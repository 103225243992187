import { Col, Row, Tabs } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { EnquiriesTable } from "./enquiriesTable";

export const ListEnquiries = () => {
  const [saleEnquiryData, setSaleEnquiryData] = useState([]);
  const [rentalEnquiryData, setRentalInquiryData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(loading);

  const getSaleEnquiries = () => {
    setLoading(true);
    axios
      .get(".netlify/functions/getSaleEnquiries", {
        baseURL: "/",
      })
      .then((response) => {
        setSaleEnquiryData(response.data.data);
        setLoading(false);
      });
  };

  const getRentalEnquiries = () => {
    setLoading(true);
    axios
      .get(".netlify/functions/getRentalEnquiries", {
        baseURL: "/",
      })
      .then((response) => {
        setRentalInquiryData(response.data.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSaleEnquiries();
    getRentalEnquiries();
  }, []);

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Sales",
      children: <EnquiriesTable enquiryData={saleEnquiryData} />,
    },
    {
      key: "2",
      label: "Rental",
      children: <EnquiriesTable enquiryData={rentalEnquiryData} />,
    },
  ];

  return (
    <Row gutter={[18, 18]}>
      <Col span={24}>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Col>
    </Row>
  );
};
