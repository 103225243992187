import { Button, Col, Row } from "antd";

import { Link, Outlet } from "react-router-dom";

export const Units = () => {
  const unitLinks = [
    {
      title: "Add Unit",
      url: "add",
    },

    {
      title: "List Units",
      url: "list",
    },
  ];

  const listUnitLinks = unitLinks.map((data) => {
    return (
      <Col xs={24} sm={24} md={4} style={{ textAlign: "center" }}>
        <Link to={data.url}>
          <Button>{data.title}</Button>
        </Link>
      </Col>
    );
  });

  return (
    <Row gutter={[18, 18]} className="main">
      {listUnitLinks}
      <Col span={24}>
        <Outlet />
      </Col>
    </Row>
  );
};
