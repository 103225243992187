import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Button, Select, Spin } from "antd";

import instance from "../../utils/axiosConfig";
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

// Usage of DebounceSelect

async function fetchUserList(username) {
  console.log("fetching user", username);
  return instance
    .post(".netlify/functions/getSearchNumber", {
      username,
      baseURL: "/",
    })
    .then((response) =>
      response.data.data.map((user) => ({
        key: user.ref,
        label: `${user.title} - ${user.intermediaryType} `,
        value: user.ref,
      }))
    );
}

export const SearchNumber = ({ setContactInfo }) => {
  console.log(setContactInfo);

  const [value, setValue] = useState([]);
  console.log(value);

  const handleClick = (value) => {
    setContactInfo(value);
    console.log(value);
  };

  const listUserId = value.map((data) => {
    return (
      <Button onClick={() => handleClick(value)}>ADD - {data.label}</Button>
    );
  });
  return (
    <>
      <DebounceSelect
        mode="multiple"
        value={value}
        placeholder="search user my contact number"
        fetchOptions={fetchUserList}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        style={{
          width: "100%",
        }}
      />
      <br></br>
      <br></br>
      {listUserId}
    </>
  );
};
