import React, { useContext, useState } from "react";
import { Button, Form, Input, Modal, message } from "antd";
import Auth0Context from "../../utils/auth0/auth0Context";
import instance from "../../utils/axiosConfig";
const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={open}
      style={{
        top: 200,
      }}
      title="Add Organisation"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="companyName"
          label="Organisation Name"
          rules={[
            {
              required: true,
              message: "Please input company name",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export const AddOrganisationModalForm = ({ reload, setReload }) => {
  const { userProfile } = useContext(Auth0Context);
  const [open, setOpen] = useState(false);

  const postOrganisation = async (organisationData) => {
    console.log(organisationData);
    return instance
      .post(".netlify/functions/postOrganisation", {
        organisationData,
        creatorProfile: userProfile,
      })
      .then(function (response) {
        message.success({
          content: `Create Successful`,
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        setReload(!reload);
      });
  };

  const onCreate = (values) => {
    console.log(values);

    postOrganisation(values);
    setOpen(false);
  };

  return (
    <div>
      <Button
        type="link"
        onClick={() => {
          setOpen(true);
        }}
      >
        <small>{"add organisation"}</small>
      </Button>
      <CollectionCreateForm
        open={open}
        onCreate={onCreate}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
