import { Button, Col, Form, Row, message } from "antd";
import { InputFormItemIntermediary } from "./formComponentsIntermediary";
import Auth0Context from "../../utils/auth0/auth0Context";
import { useContext } from "react";
import instance from "../../utils/axiosConfig";

export const AddOraginsationForm = () => {
  const { userProfile } = useContext(Auth0Context);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values);

    postOrganisation(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const postOrganisation = async (organisationData) => {
    console.log(organisationData);
    return instance
      .post(".netlify/functions/postOrganisation", {
        organisationData,
        creatorProfile: userProfile,
      })
      .then(function (response) {
        message.success({
          content: `Create Successful`,
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
      });
  };

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={[18, 18]}>
        <Col xs={12} sm={8} md={4}>
          <InputFormItemIntermediary
            label={"Organisation Name"}
            name={"companyName"}
            required={true}
            message={"Add company name"}
          />
        </Col>
        <Col xs={12} sm={8} md={8}>
          <InputFormItemIntermediary
            label={"Address"}
            name={"address"}
            required={true}
            message={"Add company name"}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <InputFormItemIntermediary
            label={"Website URL"}
            name={"websiteUrl"}
            required={true}
            message={"Add website url"}
          />
        </Col>
        <Col xs={12} sm={8} md={4}>
          <InputFormItemIntermediary
            label={"Contact Number"}
            name={"contactNumber"}
            required={true}
            message={"Add contact number"}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <InputFormItemIntermediary
            label={"Email"}
            name={"email"}
            required={true}
            message={"Add email address"}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <InputFormItemIntermediary
            label={"Company Logo - 32px"}
            name={"companyLogo"}
            required={true}
            message={"a company Logo"}
          />
        </Col>
        <br></br>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
