import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, message } from "antd";
import Auth0Context from "../../utils/auth0/auth0Context";
import instance from "../../utils/axiosConfig";
import { InputFormItemIntermediaryUpdate } from "./formComponentsUnits";

import { IntermediaryUpdateOrganisationsDropdown } from "./intermediaryUpdateOrganisationDropdown";
const CollectionCreateForm = ({
  open,
  onCreate,
  onCancel,
  intermediaryData,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...intermediaryData,
    });
  }, [form, intermediaryData]);

  console.log(intermediaryData.ref);

  return (
    <Modal
      open={open}
      style={{
        top: 200,
      }}
      title="Update Intermediary Data"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <InputFormItemIntermediaryUpdate
          intermediaryRef={intermediaryData.ref}
          label={"Contact Name"}
          name={"contactName"}
          required={true}
          message={"Add a contact name"}
        />
        <InputFormItemIntermediaryUpdate
          intermediaryRef={intermediaryData.ref}
          label={"Contact Number"}
          name={"contactNumber"}
          required={true}
          message={"Add a contact name"}
        />
        <InputFormItemIntermediaryUpdate
          intermediaryRef={intermediaryData.ref}
          label={"Email"}
          name={"email"}
          required={false}
          message={"Add a contact name"}
        />
        <IntermediaryUpdateOrganisationsDropdown
          intermediaryRef={intermediaryData.ref}
          required={false}
        />
      </Form>
    </Modal>
  );
};
export const UpdateIntermediaryModalForm = ({
  reload,
  setReload,
  intermediaryData,
}) => {
  const { userProfile } = useContext(Auth0Context);
  const [open, setOpen] = useState(false);

  const postOrganisation = async (organisationData) => {
    console.log(organisationData);
    return instance
      .post(".netlify/functions/postOrganisation", {
        organisationData,
        creatorProfile: userProfile,
      })
      .then(function (response) {
        message.success({
          content: `Create Successful`,
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        setReload(!reload);
      });
  };

  const onCreate = (values) => {
    console.log(values);

    postOrganisation(values);
    setOpen(false);
  };

  return (
    <div>
      <Button
        type="link"
        onClick={() => {
          setOpen(true);
        }}
      >
        <small>{intermediaryData.contactName}</small>
      </Button>
      <CollectionCreateForm
        open={open}
        intermediaryData={intermediaryData}
        onCreate={onCreate}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
